import { Suspense } from 'react';
import Image from 'next/image';
import Container from '../components/Container';
import Skills from 'components/Skills';

export default function Home() {
  return (
    <Suspense fallback={null}>
      <Container>
        <div className="flex flex-col justify-center items-start max-w-2xl border-gray-200 dark:border-gray-700 mx-auto pb-16">
          <div className="flex flex-col-reverse sm:flex-row items-center">
            <div className="flex flex-col pr-8">
              <h1 className="md:text-5xl tracking-tight mb-1 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl">
                {`Hi, I'm Quinn`}
              </h1>
              <h2 className="text-gray-700 dark:text-gray-200 mb-4">
                Frontend Engineer
              </h2>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                Data-driven JavaScript developer based in Columbus, Ohio.
              </p>
            </div>
            <div className="w-[80px] sm:w-[176px] relative mb-8 sm:mb-0 mr-auto">
              <Image
                alt="Quinn Sprouse"
                height={176}
                width={176}
                src="/avatar-photo.jpeg"
                sizes="30vw"
                placeholder="blur"
                priority
                className="rounded-full"
              />
            </div>
          </div>
          <Skills />
        </div>
      </Container>
    </Suspense>
  );
}
