import { useTheme } from 'next-themes';
import { reactIcon, typescriptIcon, tailwindIcon, figmaIcon, jestIcon, reactIconDark, typescriptIconDark, tailwindIconDark, figmaIconDark, jestIconDark } from 'public/icons/ICONS';
import { useEffect, useState } from 'react';

const Skills = () => {

  const [mounted, setMounted] = useState(false);
  const { resolvedTheme, setTheme } = useTheme();

  // After mounting, we have access to the theme
  useEffect(() => setMounted(true), []);

  return (
    <div className="my-8 font-sen w-full" id="skills">
      <p className="text-3xl font-bold text-black dark:text-white">Skills & Uses</p>

      <div className="text-md mt-3 mb-3 flex flex-col font-medium md:text-xl custom:text-lg">
        <p className="flex flex-row items-center border-b-[0.1px] border-gray-500 py-1 text-slate-300">
          <span className="mr-2">{mounted && resolvedTheme === 'dark' ? reactIcon : reactIconDark}</span>
          <span className="text-black dark:text-white text-lg">React</span>
          &nbsp;
          <span className='text-gray-600 dark:text-gray-300 text-sm'>as my Frontend Framework</span>
        </p>

        <p className="flex flex-row items-center border-b-[0.1px] border-gray-500 py-1 text-slate-300">
          <span className="mr-2">{mounted && resolvedTheme === 'dark' ? typescriptIcon : typescriptIconDark}</span>
          <span className="text-black dark:text-white text-lg">Typescript </span>
          &nbsp;
          <span className='text-gray-600 dark:text-gray-300 text-sm'>as my main language</span>
        </p>

        <p className="flex flex-row items-center border-b-[0.1px] border-gray-500 py-1 text-slate-300">
          <span className="mr-2">{mounted && resolvedTheme === 'dark' ? tailwindIcon : tailwindIconDark}</span>
          <span className="text-black dark:text-white text-lg">Tailwind CSS </span>
          &nbsp;
          <span className='text-gray-600 dark:text-gray-300 text-sm'>as my CSS framework</span>
        </p>

        <p className="flex flex-row items-center border-b-[0.1px] border-gray-500 py-1 text-slate-300">
          <span className="mr-2">{mounted && resolvedTheme === 'dark' ? figmaIcon : figmaIconDark}</span>
          <span className="text-black dark:text-white text-lg">Figma </span>
          &nbsp;
          <span className='text-gray-600 dark:text-gray-300 text-sm'>as my design tool</span>
        </p>

        <p className="flex flex-row items-center border-b-[0.1px] border-gray-500 py-1 text-slate-300">
          <span className="mr-2">{mounted && resolvedTheme === 'dark' ? jestIcon : jestIconDark}</span>
          <span className="text-black dark:text-white text-lg">Jest </span>
          &nbsp;
          <span className='text-gray-600 dark:text-gray-300 text-sm'>as my testing framework</span>
        </p>
      </div>

      <p className="text-lg font-medium text-slate-300">
        <span className='text-gray-600 dark:text-gray-300 text-sm'>...more skills include</span>
        &nbsp;
        <span className="text-black dark:text-white text-sm">javascript</span>,{" "}
        <span className="text-black dark:text-white text-sm">nextjs</span>,{" "}
        <span className="text-black dark:text-white text-sm">d3.js </span>{" "}
      </p>
    </div>
  );
};

export default Skills;
